import { NiubizPaymentActions } from '../actions/niubiz-payment-action-types';
import { NiubizPaymentState } from '../state/niubiz-payment-state';
import { customerReducer } from './customer-reducer';
import { paymentReduder } from './payment-reducer';

export const rootReducer = (
  { payment, customer }: NiubizPaymentState,
  action: NiubizPaymentActions,
): NiubizPaymentState => ({
  payment: paymentReduder(payment, action),
  customer: customerReducer(customer, action),
});
