import { pathWithChannel } from 'libs/constants/routes';

export function getURLRedirectChannelPage(
  path: string = 'principal',
  channel?: string,
): string {
  let redirect = pathWithChannel(path, channel);

  if (!channel) {
    redirect = redirect.replace(':channel/', '');
  }
  return redirect;
}
