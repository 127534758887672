import { MIRROR_PAGE_ACTION, RESET_ACTION } from '../staticData';

export function mirrorPageReducer(state: any, action: any) {
  switch (action.type) {
    case MIRROR_PAGE_ACTION.set:
      return {
        ...state,
        ...action.payload,
      };

    case RESET_ACTION:
      return {
        ...state,
        active: false,
        name: '',
      };

    default:
      throw new Error();
  }
}
