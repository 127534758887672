import {
  CustomerActionTypes,
  NiubizPaymentActions,
} from '../actions/niubiz-payment-action-types';
import { CustomerType } from '../state/niubiz-payment-state';

export const customerReducer = (
  state: CustomerType,
  { type, payload }: NiubizPaymentActions,
): CustomerType => {
  if (type === CustomerActionTypes.SET_CUSTOMER) {
    return {
      ...state,
      ...payload,
    };
  }
  return state;
};
