export const env: ImportMetaEnv = {
  SERVER_URL: String(import.meta.env.VITE_APP_SERVER_URL),
  NIUBIZ_MERCHANT_ID: String(import.meta.env.VITE_APP_NIUBIZ_MERCHANT_ID),
  NIUBIZ_APPLICATION_NAME: String(
    import.meta.env.VITE_APP_NIUBIZ_APPLICATION_NAME,
  ),
  NIUBIZ_BUSINESS_PROCESS: String(
    import.meta.env.VITE_APP_NIUBIZ_BUSINESS_PROCESS,
  ),
  NIUBIZ_SRC: String(import.meta.env.VITE_APP_NIUBIZ_SRC),
  SUBSCRIPTION_KEY: String(import.meta.env.VITE_APP_SUBSCRIPTION_KEY),
  PUBLIC_URL: String(import.meta.env.VITE_APP_BASE_URL),
  SHOW_BTN_NIUBIZ: Boolean(import.meta.env.VITE_APP_SHOW_BTN_NIUBIZ),
  IS_DEVELOPMENT: import.meta.env.VITE_APP_IS_DEVELOPMENT,
  RECAPTCHA_KEY: String(import.meta.env.VITE_APP_RECAPTCHA_KEY),
  APP_SUBSCRIPTION_KEY: String(import.meta.env.VITE_APP_SUBSCRIPTION_KEY),
  BASE_URL: import.meta.env.VITE_APP_BASE_URL,
  MODE: import.meta.env.MODE,
  DEV: import.meta.env.DEV,
  SSR: import.meta.env.SSR,
  PROD: import.meta.env.PROD,
  GTM_ID: String(import.meta.env.VITE_APP_GTM),
  STATIC_URL: String(import.meta.env.VITE_APP_STATIC_URL),
  PARAMS_URL: String(import.meta.env.VITE_APP_PARAMS_URL),
  CONTEXT_PATH: String(import.meta.env.VITE_APP_CONTEXT_PATH),
  DEFAULT_REQUEST_ID: String(import.meta.env.VITE_APP_DEFAULT_REQUEST_ID),
  PAYFORM_NIUBIZ: String(import.meta.env.VITE_APP_PAYFORM_NIUBIZ),
  CHANNEL_NIUBIZ: String(import.meta.env.VITE_APP_CHANNEL_NIUBIZ),
  ENABLE_AUTO_FECTIVO: JSON.parse(
    import.meta.env.VITE_APP_ENABLE_AUTO_FECTIVO || 'false',
  ),
  FLOW_WITHOUT_NIUBIZ: String(import.meta.env.VITE_APP_FLOW_WITHOUT_NIUBIZ),
  FLOW_CHANNEL_BCP: String(import.meta.env.VITE_APP_CHANNEL_BCP),
  ENABLE_AUTO_FECTIVO_PAYMENT: Boolean(
    import.meta.env.VITE_APP_ENABLE_AUTO_FECTIVO_PAYMENT,
  ),
  NIUBIZ_MERCHANT_ID_AUTO_EFECTIVO: String(
    import.meta.env.VITE_APP_NIUBIZ_MERCHANT_ID_AUTO_EFECTIVO,
  ),
  PLANS_ENALED: String(import.meta.env.VITE_APP_PLANS_ENABLED),
  SHOW_TABLE_CARDS: JSON.parse(
    import.meta.env.VITE_APP_SHOW_TABLE_CARDS || false,
  ),
  SCHEDULING_IFRAME_URL: String(import.meta.env.VITE_APP_SCHEDULING_IFRAME_URL),
};

export default env;
