import { FC, memo, ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ProtectedRoute from './protected-route';
import routes from './routes';
import { getURLRedirectChannelPage } from 'libs/utils/redirectChannel';

const AppRoutes: FC = (): ReactElement => {
  const redirect = getURLRedirectChannelPage();

  return (
    <Routes>
      <Route index element={<Navigate replace to={redirect} />} />
      {Object.values(routes).map(
        ({ path, component: Component, isPrivate }) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute isPrivate={isPrivate}>
                <Component />
              </ProtectedRoute>
            }
          />
        ),
      )}
      <Route path="*" element={<Navigate to={redirect} />} />
    </Routes>
  );
};

export default memo(AppRoutes);
