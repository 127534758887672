import { CashVehiclePlan } from 'domains/landing-autos/types/planTypes';
import { GlobalActionTypes } from '../actions/global.action.types';
import { CashVehiclePlanAction } from '../staticData';

const initialState: CashVehiclePlan = {
  showCard: false,
  activeFlow: false,
};

export const cashVehicleReducer = (
  state: CashVehiclePlan,
  { type, payload }: GlobalActionTypes,
) => {
  if (type === CashVehiclePlanAction.reset) {
    return initialState;
  }

  const actionToStateKeyMap = {
    [CashVehiclePlanAction.setShowCard]: 'showCard',
    [CashVehiclePlanAction.setActiveFlow]: 'activeFlow',
    [CashVehiclePlanAction.setQuoteValues]: 'quote',
    [CashVehiclePlanAction.reset]: 'reset',
  };

  const stateKey = actionToStateKeyMap[type as CashVehiclePlanAction];

  if (stateKey) {
    return {
      ...state,
      [stateKey]: payload,
    };
  }

  return state;
};
