import React, {
  memo,
  ReactElement,
  ReactNode,
  useReducer,
  useMemo,
} from 'react';
import NiubizPaymentContext from './niubiz-payment-context';
import { rootReducer } from './reducers/root-reducer';
import { niubizPaymentInitialState } from './state/niubiz-payment-state';

export type Props = {
  children: ReactNode;
};

const NiubizProvider: React.FC<Props> = ({ children }: Props): ReactElement => {
  const [state, dispatch] = useReducer(rootReducer, niubizPaymentInitialState);

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <div data-testid="niubiz-provider">
      <NiubizPaymentContext.Provider value={providerValue}>
        {children}
      </NiubizPaymentContext.Provider>
    </div>
  );
};

export default memo(NiubizProvider);
