import { CustomerType, PaymentType } from '../state/niubiz-payment-state';

export enum PaymentActionTypes {
  SET_PAYMENT = '@@set/payment',
}

export enum CustomerActionTypes {
  SET_CUSTOMER = '@@set/customer',
}

export type NiubizPaymentActions =
  | {
      type: PaymentActionTypes.SET_PAYMENT;
      payload: PaymentType;
    }
  | {
      type: CustomerActionTypes.SET_CUSTOMER;
      payload: CustomerType;
    };
