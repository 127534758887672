import {
  NiubizPaymentActions,
  PaymentActionTypes,
} from '../actions/niubiz-payment-action-types';
import { PaymentType } from '../state/niubiz-payment-state';

export const paymentReduder = (
  state: PaymentType,
  { type, payload }: NiubizPaymentActions,
): PaymentType => {
  if (type === PaymentActionTypes.SET_PAYMENT) {
    return {
      ...state,
      ...payload,
    };
  }
  return state;
};
