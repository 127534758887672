import { useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import Authorization from '../../core-base/authentication/models/authorization';

const useRecaptchaV3 = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(() => {
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available');
      return;
    }

    const authorization = Authorization.getInstance();
    authorization.executeRecaptcha = executeRecaptcha;
  }, [executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);
};
export default useRecaptchaV3;
