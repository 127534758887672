import { QUICKS_ACTION, RESET_ACTION } from '../staticData';

export function quicksReducer(state: any[], action: any) {
  switch (action.type) {
    case QUICKS_ACTION.set:
      return action.payload;

    case RESET_ACTION:
      return [];

    default:
      throw new Error();
  }
}
