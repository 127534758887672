import { QUOTATION_PLAN_ACTION, RESET_ACTION } from '../staticData';

export function quotationPlanReducer(state: any, action: any) {
  switch (action.type) {
    case QUOTATION_PLAN_ACTION.set:
      return action.payload;

    case RESET_ACTION:
      return {};

    default:
      throw new Error();
  }
}
