import { createContext, Dispatch } from 'react';
import { NiubizPaymentActions } from './actions/niubiz-payment-action-types';
import {
  niubizPaymentInitialState,
  NiubizPaymentState,
} from './state/niubiz-payment-state';

const NiubizPaymentContext = createContext<{
  state: NiubizPaymentState;
  dispatch: Dispatch<NiubizPaymentActions>;
}>({
  state: niubizPaymentInitialState,
  dispatch: () => null,
});

export default NiubizPaymentContext;
