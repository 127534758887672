import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'app/app.query.config';
import AppRoutes from 'app/app.routes';
import AppContextWrapper from 'domains/commons/contexts/global/global-provider';
import NiubizPrivider from 'domains/commons/contexts/niubiz-payment/niubiz-privider';
import React, { memo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './app.scss';
import { ReactTagManager } from 'react-gtm-ts';
import env from 'libs/constants/env';


ReactTagManager.init({
  code: env.GTM_ID as string,
  debug: false,
  performance: true,
});


const App: React.FC = () => (
  <AppContextWrapper>
    <div className="app">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <NiubizPrivider>
            <AppRoutes />
          </NiubizPrivider>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  </AppContextWrapper>
);

export default memo(App);
