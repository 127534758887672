import { env } from 'libs/constants/env';

export type PaymentType = {
  jobId: string;
  sessionKey: string;
  merchantId?: string;
  amount: number;
  transactionId: string;
};

export type CustomerType = {
  cardholdername: string;
  cardholderlastname: string;
  cardholderemail: string;
};

export type NiubizPaymentState = {
  payment: PaymentType;
  customer: CustomerType;
};

export const niubizPaymentInitialState: NiubizPaymentState = {
  payment: {
    jobId: '',
    sessionKey: '',
    merchantId: env.NIUBIZ_MERCHANT_ID,
    amount: 0,
    transactionId: '',
  },
  customer: {
    cardholderemail: '',
    cardholderlastname: '',
    cardholdername: '',
  },
};
