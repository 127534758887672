export const AE_OFFER_CODE_MAIN = 'AUTOEfectivoAValorComercialTotal';
export const AE_OFFER_CODE_SECONDARY = 'AUTOEfectivoAMontoFijoTotal';

const AUTOEFECTIVO_PLANS = {
  general: 'General',
  completo: 'AutoEfectivo Completo',
  esencial: 'AutoEfectivo Esencial',
  taxi95: 'Para tu taxi y más - 95',
  taxi85: 'Para tu taxi 85',
};

export default AUTOEFECTIVO_PLANS;
