import AUTOEFECTIVO_PLANS from "domains/new-plan/constants/plans";
import { AUTOEFECTIVO_PATH_ACTION, RESET_ACTION } from "../staticData";

export function autoefectivoPathReducer(state: any, action: any) {
    switch (action.type) {
        case AUTOEFECTIVO_PATH_ACTION.set:
            return action.payload;

        case RESET_ACTION:
            return `${AUTOEFECTIVO_PLANS.general}`;

        default:
            throw new Error()
    }
}