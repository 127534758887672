import loadable from '@loadable/component';
import { RoutePaths } from './routes.type';

export const LandingRoutes = {
  component: loadable(
    () => import('../domains/landing-autos/containers/app/app'),
  ),
  path: RoutePaths.Landing,
  name: 'Landing',
  isPrivate: false,
};

export const QuotationRoutes = {
  component: loadable(
    () => import('../domains/quotation/containers/choose-plan/choose-plan'),
  ),
  path: RoutePaths.Quotation,
  name: 'Quotations',
  isPrivate: true,
};

export const NewQuotationRoutes = {
  component: loadable(() => import('../domains/new-quotation/index')),
  path: RoutePaths.NewQuotation,
  name: 'Quotations',
  isPrivate: false,
};

export const SummaryRoutes = {
  component: loadable(
    () => import('../domains/quotation/containers/confirmation/confirmation'),
  ),
  path: RoutePaths.Summary,
  name: 'summary',
  isPrivate: true,
};

export const ConfirmationRoutes = {
  component: loadable(
    () =>
      import(
        '../domains/quotation/containers/payment-confirmed/payment-confirmed'
      ),
  ),
  path: RoutePaths.Confirmation,
  name: 'confirmation',
  isPrivate: true,
};

export const ErrorPaymentRoutes = {
  component: loadable(
    () =>
      import(
        '../domains/quotation/containers/payment/containers/payment-niubiz/payment-niubiz'
      ),
  ),
  path: RoutePaths.ErrorPayment,
  name: 'error-payment',
  isPrivate: true,
};

export const SchedulingRoutes = {
  component: loadable(
    () => import('../domains/scheduling/containers/scheduling'),
  ),
  path: RoutePaths.Scheduling,
  name: 'Scheduling',
  isPrivate: false,
};

export const SchedulingThanksRoutes = {
  component: loadable(
    () =>
      import(
        '../domains/scheduling/containers/registration-page/registration-page'
      ),
  ),
  path: RoutePaths.SchedulingThanks,
  name: 'SchedulingThanks',
  isPrivate: false,
};

export const LandingAutoEfectivoRoutes = {
  component: loadable(() => import('../domains/auto-efectivo/containers/app')),
  path: RoutePaths.AutoEfectivoExperiencia,
  name: 'NewLandingAutoefectivo',
  isPrivate: false,
};

export const QuotationAutoEfectivoRoutes = {
  component: loadable(
    () => import('../domains/auto-efectivo/quotation/containers/app'),
  ),
  path: RoutePaths.QuotationAutoEfectivo,
  name: 'QuotationAutoefectivo',
  isPrivate: true,
};

export const PaymentAutoEfectivoRoutes = {
  component: loadable(
    () => import('../domains/auto-efectivo/payment/containers/app'),
  ),
  path: RoutePaths.PaymentAutoEfectivo,
  name: 'PaymentAutoefectivo',
  isPrivate: true,
};

export const ConfirmationAutoEfectivoRoutes = {
  component: loadable(
    () => import('../domains/auto-efectivo/thanks/containers/app-e2e'),
  ),
  path: RoutePaths.ConfirmationAE,
  name: 'ConfirmationAE',
  isPrivate: true,
};
