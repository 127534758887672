import { ACCESS_TOKEN_ACTION, RESET_ACTION } from '../staticData';

export function accessTokenReducer(state: any, action: any) {
  switch (action.type) {
    case ACCESS_TOKEN_ACTION.set:
      return action.payload;

    case RESET_ACTION:
      return '';

    default:
      throw new Error();
  }
}
