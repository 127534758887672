import { RESET_ACTION, UBIGEOS_ACTION } from '../staticData';

export function ubigeosReducer(state: any[], action: any) {
  switch (action.type) {
    case UBIGEOS_ACTION.set:
      return action.payload;

    case RESET_ACTION:
      return [];

    default:
      throw new Error();
  }
}
