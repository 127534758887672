import { LoadableComponent } from '@loadable/component';
import ROUTES, {
  pathWithBaseUrl,
  pathWithChannel,
} from 'libs/constants/routes';
import { PathRouteProps } from 'react-router-dom';

enum Pages {
  Landing,
  LandingWithChannel,
  LandingMirror,
  LandingBex,
  NewQuotation,
  Quotation,
  QuotationWithChannel,
  QuotationMirrow,
  QuotationBex,
  QuotationAutoEfectivo,
  QuotationMirrorAutoEfectivo,
  PaymentAutoEfectivo,
  PaymentMirrorAutoEfectivo,
  RemarketingPaymentAE,
  RemarketingConfirmationAE,
  Summary,
  SummaryWithChannel,
  PagoWithChannel,
  SummaryMirrow,
  SummaryBex,
  Confirmation,
  ConfirmationWithChannel,
  ConfirmationMirrow,
  ConfirmationBex,
  ThanksAE,
  ConfirmationAE,
  ConfirmationMirrorAutoEfectivo,
  ErrorPayment,
  ErrorPaymentWithChannel,
  Timeout,
  NewPlan,
  PublicClosedLow,
  PublicClosedHigh,
  PublicBCP,
  PublicYAPE,
  Thanks,
  Derivation,
  LandingBcp,
  AutoefectivoTaxi,
  AutoefectivoLaunch,
  SchedulingAutoEfectivo,
  SchedulingThanksAutoEfectivo,
  Scheduling,
  AutoEfectivoExperiencia,
  LandingMirrorAutoEfectivo,
  SchedulingThanks,
}

const RoutePaths = {
  Root: pathWithBaseUrl(''),
  Landing: pathWithBaseUrl('principal'),
  LandingWithChannel: pathWithChannel('principal'),
  LandingMirrow: pathWithBaseUrl('principal-experiencia'),
  LandingBex: pathWithBaseUrl('principal-bex'),
  Quotation: pathWithBaseUrl('cotizacion'),
  NewQuotation: pathWithBaseUrl('nuevo-cotizacion'),
  QuotationWithChannel: pathWithChannel('cotizacion'),
  QuotationMirrow: pathWithBaseUrl('cotizacion-experiencia'),
  QuotationBex: pathWithBaseUrl('cotizacion-bex'),
  QuotationAutoEfectivo: ROUTES.QUOTATION_AE,
  QuotationMirrorAutoEfectivo: ROUTES.QUOTATION_MIRROR_AE,
  PaymentAutoEfectivo: ROUTES.PAYMENT_AE,
  PaymentMirrorAutoEfectivo: ROUTES.PAYMENT_MIRROR_AE,
  RemarketingPaymentAE: pathWithBaseUrl('autoefectivo-cross'),
  RemarketingConfirmationAE: pathWithBaseUrl('confirmacion-autoefectivo-cross'),
  Summary: pathWithBaseUrl('resumen'),
  SummaryWithChannel: pathWithChannel('resumen'),
  PayWithChannel: pathWithChannel('pago'),
  SummaryMirrow: pathWithBaseUrl('resumen-experiencia'),
  SummaryBex: pathWithBaseUrl('resumen-bex'),
  Confirmation: pathWithBaseUrl('confirmacion'),
  ConfirmationWithChannel: pathWithChannel('confirmacion'),
  ConfirmationMirrow: pathWithBaseUrl('confirmacion-experiencia'),
  ConfirmationBex: pathWithBaseUrl('confirmacion-bex'),
  ThanksAE: pathWithBaseUrl('gracias-autoefectivo-experiencia'),
  ConfirmationAE: ROUTES.CONFIRMATION_AE,
  ConfirmationMirrorAutoEfectivo: ROUTES.CONFIRMATION_MIRROR_AE,
  ErrorPayment: pathWithBaseUrl('error-payment'),
  ErrorPaymentWithChannel: pathWithChannel('error-payment'),
  Timeout: pathWithBaseUrl('timeout'),
  NewPlan: pathWithBaseUrl('autoefectivo'),
  AutoefectivoLaunch: pathWithBaseUrl('autoefectivo-lanzamiento'),
  PublicClosedLow: pathWithBaseUrl('autoefectivo-a'),
  PublicClosedHigh: pathWithBaseUrl('autoefectivo-b'),
  PublicBCP: pathWithBaseUrl('autoefectivo-bcp'),
  PublicYAPE: pathWithBaseUrl('autoefectivo-yape'),
  Thanks: pathWithBaseUrl('gracias'),
  Derivation: pathWithBaseUrl('derivacion'),
  LandingBcp: pathWithBaseUrl('principal-bcp'),
  AutoefectivoTaxi: pathWithBaseUrl('autoefectivo-taxi'),
  SchedulingAutoEfectivo: pathWithBaseUrl('autoefectivo-agendamiento'),
  SchedulingThanksAutoEfectivo: pathWithBaseUrl(
    'autoefectivo-agendamiento-gracias',
  ),
  Scheduling: pathWithBaseUrl('agendamiento'),
  AutoEfectivoExperiencia: ROUTES.LANDING_AE,
  LandingMirrorAutoEfectivo: ROUTES.LANDING_MIRROR_AE,
  SchedulingThanks: pathWithBaseUrl('agendamiento-gracias'),
} as const;

type PathRouteCustomProps = {
  name?: string;
  component: LoadableComponent<{}> | React.FC;
  isPrivate: boolean;
};

type Routes = Record<Pages, PathRouteProps & PathRouteCustomProps>;

export type { Routes };
export { Pages, RoutePaths };
