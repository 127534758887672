import { RESET_ACTION, VEHICLE_ACTION } from '../staticData';

export function vehicleReducer(state: any, action: any) {
  switch (action.type) {
    case VEHICLE_ACTION.set:
      return { ...state, ...action.payload };

    case RESET_ACTION:
      return {};

    default:
      throw new Error();
  }
}
