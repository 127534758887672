import { AFFILIATION_ACTION, RESET_ACTION } from '../staticData';

export function affiliationReducer(state: any, action: any) {
  switch (action.type) {
    case AFFILIATION_ACTION.set:
      return action.payload;

    case RESET_ACTION:
      return {};

    default:
      throw new Error();
  }
}
