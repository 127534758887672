import { FC, memo, ReactElement } from 'react';
import { Navigate, useParams, useLocation } from 'react-router-dom';

import { getURLRedirectChannelPage } from 'libs/utils/redirectChannel';
import Authorization from 'domains/core-base/authentication/models/authorization';

export type Props = {
  isPrivate: boolean;
  children: ReactElement;
};

const ProtectedRoute: FC<Props> = ({
  children,
  isPrivate,
}: Props): ReactElement => {
  const { channel = null } = useParams();
  const location = useLocation();
  const existFunctionCaptcha =
    Authorization.getInstance().executeRecaptcha === undefined;
  const existChannelInSession = sessionStorage.getItem('channel');
  if (channel === null && existChannelInSession !== null) {
    sessionStorage.removeItem('channel');
  }
  if (channel) {
    sessionStorage.setItem('channel', channel);
  }

  const isExperience = location.pathname.includes('-experiencia')
    ? 'principal-experiencia'
    : 'principal';

  const redirect = getURLRedirectChannelPage(isExperience, channel!);
  if (isPrivate && existFunctionCaptcha) {
    return <Navigate to={redirect} replace />;
  }
  return children;
};

export default memo(ProtectedRoute);
