import { REQUEST_ACTION, RESET_ACTION } from '../staticData';

export function requestReducer(state: any, action: any) {
  switch (action.type) {
    case REQUEST_ACTION.set:
      return { ...state, ...action.payload };

    case REQUEST_ACTION.setId:
      return { ...state, id: action.payload };

    case RESET_ACTION:
      return {};

    default:
      throw new Error();
  }
}
