import { RECOMMENDED_PLANS_ACTION, RESET_ACTION } from '../staticData';

export function recommendedPlansReducer(state: string[], action: any) {
  switch (action.type) {
    case RECOMMENDED_PLANS_ACTION.set:
      return action.payload;

    case RESET_ACTION:
      return [];

    default:
      throw new Error();
  }
}
