import { DATALAYER_ACTION, RESET_ACTION } from '../staticData';

export function dataLayerReducer(state: any, action: any) {
  switch (action.type) {
    case DATALAYER_ACTION.set:
      return { ...state, ...action.payload };

    case RESET_ACTION:
      return {
        hasPlan: false,
        hasPlate: false,
        hasDni: false,
        hasAddress: false,
        customerExist: false,
        documentType: 'DNI',
      };

    default:
      throw new Error();
  }
}
