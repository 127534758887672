import App from 'app/app';
import env from 'libs/constants/env';
import { StrictMode } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import RecaptchaConsumer from './components/recaptcha-consumer/recaptcha-consumer';

import './assets/styles/index.scss';
const Main = () => (
  <StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={env.RECAPTCHA_KEY}>
      <App />
      <RecaptchaConsumer />
    </GoogleReCaptchaProvider>
  </StrictMode>
);

const container = document.getElementById('root');
if (container) {
  if (container.hasChildNodes()) {
    hydrateRoot(container, <Main />);
  } else {
    const root = createRoot(container);
    root.render(<Main />);
  }
}
