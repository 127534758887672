import { RESET_ACTION, ORACLE_ACTION } from '../staticData';

export function oracleReducer(state: any, action: any) {
  switch (action.type) {
    case ORACLE_ACTION.setPartyId:
      return { ...state, partyId: action.payload };

    case ORACLE_ACTION.setOptyNumber:
      return { ...state, optyNumber: action.payload };

    case ORACLE_ACTION.setAll:
      return { ...state, ...action.payload };

    case RESET_ACTION:
      return {};

    default:
      throw new Error();
  }
}
