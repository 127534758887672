import { GlobalActionTypes } from '../actions/global.action.types';
import { RecaptchaAction } from '../staticData';
import { RecaptchaType } from '../types/recaptcha.types';

export const recaptchaReducer = (
  state: RecaptchaType,
  { type, payload }: GlobalActionTypes,
) => {
  if (type == RecaptchaAction.setStatus) {
    return {
      ...state,
      valid: payload,
    };
  }
  if (type == RecaptchaAction.setLoadeGtm) {
    return {
      ...state,
      loadedGtmVendor: payload,
    };
  }
  return state;
};
