/* eslint-disable no-use-before-define */
class Authorization {
	private static instance: Authorization;

	private _token = '';

	private _executeRecaptcha: Undefined<Recaptcha>;

	public get token(): string {
		return this._token;
	}

	public set token(value: string) {
		this._token = value;
	}

	public get executeRecaptcha(): Recaptcha {
		return this._executeRecaptcha as Recaptcha;
	}

	public set executeRecaptcha(value: Undefined<Recaptcha>) {
		this._executeRecaptcha = value;
	}

	public static getInstance(): Authorization {
		if (!Authorization.instance) {
			Authorization.instance = new Authorization();
		}
		return Authorization.instance;
	}
}

export default Authorization;
