import { QUOTAS_ACTION, RESET_ACTION } from '../staticData';

export function quotasReducer(state: any[], action: any) {
  switch (action.type) {
    case QUOTAS_ACTION.set:
      return action.payload;

    case RESET_ACTION:
      return [];

    default:
      throw new Error();
  }
}
