import {
  LandingRoutes,
  QuotationRoutes,
  SummaryRoutes,
  ConfirmationRoutes,
  ErrorPaymentRoutes,
  SchedulingRoutes,
  NewQuotationRoutes,
  SchedulingThanksRoutes,
  LandingAutoEfectivoRoutes,
  QuotationAutoEfectivoRoutes,
  PaymentAutoEfectivoRoutes,
  ConfirmationAutoEfectivoRoutes,
} from './flowRoutes';
import loadable from '@loadable/component';

import { Pages, RoutePaths, Routes } from './routes.type';

const routes: Routes = {
  [Pages.Landing]: {
    ...LandingRoutes,
  },
  [Pages.LandingWithChannel]: {
    ...LandingRoutes,
    path: RoutePaths.LandingWithChannel,
  },
  [Pages.LandingMirror]: {
    ...LandingRoutes,
    path: RoutePaths.LandingMirrow,
    name: 'LandingMirror',
  },
  [Pages.LandingBex]: {
    ...LandingRoutes,
    path: RoutePaths.LandingBex,
    name: 'LandingBex',
  },
  [Pages.NewQuotation]: {
    ...NewQuotationRoutes,
  },
  [Pages.Quotation]: {
    ...QuotationRoutes,
  },
  [Pages.QuotationWithChannel]: {
    ...QuotationRoutes,
    path: RoutePaths.QuotationWithChannel,
    name: 'QuotationWithChannel',
  },
  [Pages.QuotationMirrow]: {
    component: loadable(() => import('../domains/new-quotation/index')),
    path: RoutePaths.QuotationMirrow,
    name: 'QuotationsMirrow',
    isPrivate: true,
  },
  [Pages.QuotationBex]: {
    ...QuotationRoutes,
    path: RoutePaths.QuotationBex,
    name: 'QuotationsBex',
  },
  [Pages.QuotationAutoEfectivo]: {
    ...QuotationAutoEfectivoRoutes,
  },
  [Pages.QuotationMirrorAutoEfectivo]: {
    ...QuotationAutoEfectivoRoutes,
    path: RoutePaths.QuotationMirrorAutoEfectivo,
    name: 'QuotationMirrorAutoEfectivo',
  },
  [Pages.PaymentAutoEfectivo]: {
    ...PaymentAutoEfectivoRoutes,
  },
  [Pages.PaymentMirrorAutoEfectivo]: {
    ...PaymentAutoEfectivoRoutes,
    path: RoutePaths.PaymentMirrorAutoEfectivo,
    name: 'PaymentMirrorAutoEfectivo',
  },
  [Pages.RemarketingPaymentAE]: {
    component: loadable(
      () =>
        import('../domains/auto-efectivo/remarketing/payment/containers/app'),
    ),
    path: RoutePaths.RemarketingPaymentAE,
    name: 'RemarketingPaymentAutoefectivo',
    isPrivate: false,
  },
  [Pages.RemarketingConfirmationAE]: {
    component: loadable(
      () =>
        import('../domains/auto-efectivo/remarketing/thanks/containers/app'),
    ),
    path: RoutePaths.RemarketingConfirmationAE,
    name: 'RemarketingConfirmationAutoefectivo',
    isPrivate: true,
  },
  [Pages.Summary]: {
    ...SummaryRoutes,
    name: 'summary',
  },
  [Pages.SummaryWithChannel]: {
    ...SummaryRoutes,
    path: RoutePaths.SummaryWithChannel,
    name: 'SummaryWithChannel',
  },
  [Pages.PagoWithChannel]: {
    ...SummaryRoutes,
  },
  [Pages.SummaryMirrow]: {
    ...SummaryRoutes,
    path: RoutePaths.SummaryMirrow,
    name: 'summaryMirrow',
  },
  [Pages.SummaryBex]: {
    ...SummaryRoutes,
    path: RoutePaths.SummaryBex,
    name: 'SummaryBex',
  },
  [Pages.Confirmation]: {
    ...ConfirmationRoutes,
  },
  [Pages.ConfirmationWithChannel]: {
    ...ConfirmationRoutes,
    path: RoutePaths.ConfirmationWithChannel,
    name: 'ConfirmationWithChannel',
  },
  [Pages.ConfirmationMirrow]: {
    ...ConfirmationRoutes,
    path: RoutePaths.ConfirmationMirrow,
    name: 'confirmationMirrow',
  },
  [Pages.ConfirmationBex]: {
    ...ConfirmationRoutes,
    path: RoutePaths.ConfirmationBex,
    name: 'ConfirmationBex',
  },
  [Pages.ThanksAE]: {
    component: loadable(
      () => import('../domains/auto-efectivo/thanks/containers/app'),
    ),
    path: RoutePaths.ThanksAE,
    name: 'ThanksAE',
    isPrivate: true,
  },
  [Pages.ConfirmationAE]: {
    ...ConfirmationAutoEfectivoRoutes,
  },
  [Pages.ConfirmationMirrorAutoEfectivo]: {
    ...ConfirmationAutoEfectivoRoutes,
    path: RoutePaths.ConfirmationMirrorAutoEfectivo,
    name: 'ConfirmationMirrorAutoEfectivo',
  },
  [Pages.ErrorPayment]: {
    ...ErrorPaymentRoutes,
  },
  [Pages.ErrorPaymentWithChannel]: {
    ...ErrorPaymentRoutes,
    path: RoutePaths.ErrorPaymentWithChannel,
    name: 'ErrorPaymentWithChannel',
  },
  [Pages.SchedulingAutoEfectivo]: {
    component: loadable(
      () => import('../domains/auto-efectivo/scheduling/containers/app'),
    ),
    path: RoutePaths.SchedulingAutoEfectivo,
    name: 'SchedulingAutoEfectivo',
    isPrivate: false,
  },
  [Pages.SchedulingThanksAutoEfectivo]: {
    component: loadable(
      () => import('../domains/auto-efectivo/scheduling/thanks/containers/app'),
    ),
    path: RoutePaths.SchedulingThanksAutoEfectivo,
    name: 'SchedulingThanksAutoEfectivo',
    isPrivate: false,
  },
  [Pages.Scheduling]: {
    ...SchedulingRoutes,
    path: RoutePaths.Scheduling,
    name: 'Scheduling',
  },
  [Pages.SchedulingThanks]: {
    ...SchedulingThanksRoutes,
    path: RoutePaths.SchedulingThanks,
    name: 'SchedulingThanks',
  },
  [Pages.Timeout]: {
    component: loadable(
      () =>
        import(
          '../domains/quotation/containers/payment/containers/payment-niubiz/payment-niubiz'
        ),
    ),
    path: RoutePaths.Timeout,
    name: 'timeout',
    isPrivate: true,
  },
  [Pages.NewPlan]: {
    component: loadable(() => import('../domains/new-plan/containers/app')),
    path: RoutePaths.NewPlan,
    name: 'NewPlan',
    isPrivate: false,
  },
  [Pages.AutoEfectivoExperiencia]: {
    ...LandingAutoEfectivoRoutes,
  },
  [Pages.LandingMirrorAutoEfectivo]: {
    ...LandingAutoEfectivoRoutes,
    path: RoutePaths.LandingMirrorAutoEfectivo,
    name: 'LandingMirrorAutoefectivo',
  },
  [Pages.PublicClosedLow]: {
    component: loadable(
      () => import('../domains/new-plan/containers/new-plan'),
    ),
    path: RoutePaths.PublicClosedLow,
    name: 'PublicClosedLow',
    isPrivate: false,
  },
  [Pages.PublicClosedHigh]: {
    component: loadable(
      () => import('../domains/new-plan/containers/new-plan'),
    ),
    path: RoutePaths.PublicClosedHigh,
    name: 'PublicClosedHigh',
    isPrivate: false,
  },
  [Pages.PublicBCP]: {
    component: loadable(
      () => import('../domains/new-plan/containers/new-plan'),
    ),
    path: RoutePaths.PublicBCP,
    name: 'PublicBCP',
    isPrivate: false,
  },
  [Pages.PublicYAPE]: {
    component: loadable(
      () => import('../domains/new-plan/containers/new-plan'),
    ),
    path: RoutePaths.PublicYAPE,
    name: 'PublicYAPE',
    isPrivate: false,
  },
  [Pages.Thanks]: {
    component: loadable(
      () =>
        import(
          '../domains/new-plan/containers/registation-page/registation-page'
        ),
    ),
    path: RoutePaths.Thanks,
    name: 'RegistationPage',
    isPrivate: false,
  },
  [Pages.Derivation]: {
    component: loadable(
      () => import('../domains/derivation/containers/derivation'),
    ),
    path: RoutePaths.Derivation,
    name: 'Derivation',
    isPrivate: false,
  },
  [Pages.LandingBcp]: {
    component: loadable(
      () => import('../domains/landing-bcp/containers/landing-bcp'),
    ),
    path: RoutePaths.LandingBcp,
    name: 'LandingBcp',
    isPrivate: false,
  },
  [Pages.AutoefectivoLaunch]: {
    component: loadable(
      () => import('../domains/new-plan/containers/new-plan'),
    ),
    path: RoutePaths.AutoefectivoLaunch,
    name: 'LandingLaunch',
    isPrivate: false,
  },
  [Pages.AutoefectivoTaxi]: {
    component: loadable(
      () =>
        import(
          '../domains/new-plan/containers/autoefectivo-taxi/autoefectivo-taxi'
        ),
    ),
    path: RoutePaths.AutoefectivoTaxi,
    name: 'NewPlan',
    isPrivate: false,
  },
};

export default routes;
