import AUTOEFECTIVO_PLANS from "domains/new-plan/constants/plans";
import { NEW_PLAN_ACTION, RESET_ACTION } from "../staticData";

export function newPlanReducer(state: any, action: any) {
    switch (action.type) {
        case NEW_PLAN_ACTION.set:
            return action.payload;

        case RESET_ACTION:
            return `${AUTOEFECTIVO_PLANS.general}`;

        default:
            throw new Error()
    }
}